@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$font_default: 14px;
$font_body1: 18px;
$font_h1: 2rem;
$font_h2: 1.5rem;
$font_h4: 1rem;

// Font family
$font_family: 'Noto Sans', 'Roboto', sans-serif;
