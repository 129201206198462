.wrapper {
  padding: 2rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.innerWrapper {
  border: 1px solid #e5e5e5;
  border-radius: 1.3rem;
  padding: 2rem;
}

.innerWrapperTitle {
  margin: 0;
  color: #2794dc;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.innerWrapperSwimlanesContainer {
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
}

.swimlaneContainer {
  width: 50%;

  h3 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
}

.swimlane {
  height: 70vh;
  padding: 1rem;
  border: 1px solid #e5e5e5;
  border-radius: 1.3rem;
  overflow-y: auto;
}

.swimlaneContainerHeader {
  display: flex;
  justify-content: space-between;
}

.swimlaneContainerHeader button {
  border: none;
  background: none;
  margin-right: 1rem;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
    cursor: auto;
  }

  cursor: pointer;
}

.swimlane h4 {
  margin: 0 0 1rem 0;
}

.blur {
  filter: blur(0.15rem);
}

.popup {
  padding: 15px 20px;
  background-color: #333333;
  position: absolute;
  top: 1%;
  left: 45%;
  border-radius: 5px;
  color: #FFFFFF;
}
