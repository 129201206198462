@import '../../lib/variables.scss';

.swimlaneItem {
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 1.2rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.swimlaneItem:not(:last-child) {
  margin-bottom: 1rem;
}

.swimlaneItemName {
  font-weight: 700;
  font-size: 1.3rem;
  margin: 0 0 0.2rem 0;
}

.swimlaneItemSecuritynumber {
  font-size: 1.2rem;
  margin: 0 0 0.2rem 0;
  height: 1.5rem;
}

.swimlaneItemTime {
  margin: 1.5rem 0 0 0;
  font-size: 1rem;
}

.swimlaneItemTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameContainer {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
}

.copyLogo_crossLogo_container {
  width: 15%;
  display: flex;
  justify-content: space-between
}

.copyLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyLogo {
  height: 24px;
  cursor: pointer;
}

.swimlaneItemTopRow img {
  height: 1.7rem;
}

.swimlaneItemBgBlueToGreen {
  background: $blue-to-green-gradient;
  color: white;
}

.swimlaneItemBgLightBlueToGreen {
  background: linear-gradient(89.41deg, #3cc9e7 3.1%, #23d5d5 99.09%);
  color: white;
}

.swimlaneItemButtonContainer {
  display: flex;
}

.swimlaneItemButton {
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.textWhite {
  color: white;
}

.swimlaneItemAppointmentStatusGreenToBlue {
  background: $blue-to-green-gradient;
  color: white;
}

.swimlaneItemAppointmentStatusWhiteAndGreen {
  background-color: white;
}

.swimlaneItemAppointmentStatusWhiteAndGreen p {
  background: $blue-to-green-gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swimlaneItemAppointmentStatus {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: absolute;
  cursor: pointer;
  bottom: -1rem;
  right: -1rem;
  padding: 1rem 2.5rem 2rem 1.5rem;
  border-radius: 1rem;
  font-size: 1rem;

  p {
    margin: 0;
    font-weight: bold;
  }
}


