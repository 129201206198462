.swimlaneItemAppointmentDataRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin: 1.5rem 0 0 0;
  max-width: 100%;
}

.swimlaneItemAppointmentDataItem {
  margin: 0;
  font-weight: bold;
}

.surveyText {
  cursor: pointer;
}

.surveyText .tooltiptext {
  visibility: hidden;
  text-align: center;
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  height: 20px;
}

.surveyText:hover .tooltiptext {
  visibility: visible;
}
