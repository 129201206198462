@import 'lib/variables.scss';

.wrapper {
    position: absolute;
    right: 10rem;
    top: -0.5rem;
    cursor: pointer;
    width: max-content;
    border-radius: 1.8rem;
    font-size: 1.2rem;
    border: double 1px transparent;
    background-image: linear-gradient(white, white), $blue-to-green-gradient;
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.dropdown {
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
}

.dropdownOption {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 2.5rem 0 1.2rem;
    height: 2.5rem;
    font-size: medium;
}

.dropdownSelectedOption {
    font-weight: 600;
    font-size: large;
}

.dropdownSelectedOptionArrowRotate img {
    transform: rotate(180deg);
}

.dropdownOption img {
    width: 1rem;
    position: absolute;
    right: 1rem;
}

hr {
    width: 90%;
    height: 1px;
    border: 0 none;
    margin-top: 0.5rem;
    background-image: $blue-to-green-gradient;
    margin: 0 2.5rem 0 1.2rem;
}
